import { useState, useEffect } from "react";
import { getSelectUser } from "./select-user.resource";
import { get } from "lodash";
import postLoginRedirect from "../login/login.helpers";

export function useSwitchUser() {
  const [user, switchUser] = useState();
  useEffect(() => {
    if (user) {
      const subscription = getSelectUser(user.id).subscribe(
        (results) => {
          postLoginRedirect(results.instance_url ? results.instance_url : window.location.origin, user);
        },
        (e) => {
          if (e.status === 418) {
            const redirectUrl = get(e, "data.body.redirect_url");
            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              throw new Error(
                `POST /token responded with 418 to indicate user is on wrong domain, but directory service did not specify what the correct domain is`
              );
            }
          }
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [user]);
  return switchUser;
}
