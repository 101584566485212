import { fetchAsObservable, useFetcher } from "fetcher!sofe";

export function getSelectUser(user_exchange_id) {
  const body = {
    grant_type: "user_exchange",
    user_exchange_id,
  };

  return fetchAsObservable(`/token`, {
    passThrough401: true,
    method: "post",
    headers: {
      Authorization: "Basic " + btoa("TaxUI:f7fsf29adsy9fg"),
    },
    body,
  });
}

export function useGetEntities() {
  return useFetcher(`/wg/entities`, {
    pluck: "users",
  });
}
