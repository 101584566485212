import { mobileResponsiveBasePath } from "../login-ui";

export default function postLoginRedirect(redirectUrl, user) {
  const validRedirectUrls = /^https?:\/\/[\w-]*\.([\w]*\.)?(clientportal|canopy)(\.dev|tax\.com|\.ninja|\.com)/;
  const validLocalUrl = /^http:\/\/127\.0\.0\.1:\d\d\d\d\d?/;
  if (redirectUrl && (redirectUrl.match(validRedirectUrls) || redirectUrl.match(validLocalUrl))) {
    window.location.href = adjustIfClient(redirectUrl);
  } else {
    const { origin, host } = window.location;
    const destination = window.responsiveMode ? `https://${host}/m/` : origin;
    window.location.href = adjustIfClient(destination);
  }

  function adjustIfClient(url) {
    return user?.role === "Client" && !url.includes("/m")
      ? `${url.replace(/\/$/, "")}${mobileResponsiveBasePath}`
      : url;
  }
}
